import { QueryFunction, useQuery } from "@tanstack/react-query";
import { solicitudes as solicitudesAPI } from "api/solicitudes";
import {
  SolicitudAnalisisRequest,
  SolicitudConsumoAnalisisResponse,
} from "api/types/originacion/services/analisisSolicitudTypes";

const querySolicitud: QueryFunction<
  SolicitudConsumoAnalisisResponse,
  [string, SolicitudAnalisisRequest]
> = async ({ queryKey, meta }) =>
  solicitudesAPI(meta?.token as string).getSolicitudConsumo(queryKey[1]);

export const useSolicitudConsumo = (solicitudId: number, enabled: boolean) => {
  return useQuery(
    ["detalleSolicitudConsumo", { id: solicitudId }],
    querySolicitud,
    {
      enabled: enabled,
      keepPreviousData: true,
    }
  );
};
