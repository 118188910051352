import React, { ReactNode } from "react";
import styled from "@material-ui/core/styles/styled";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import MuiIconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import closeIcon from "assets/icons/ic-close.svg";
import backIcon from "assets/icons/ic-back.svg";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import pxToRem from "libs/pxToRem";
import ContentSection from "../../contentSection/ContentSection";

const NAV_BAR_HEIGHT = 56;
const TABS_HEIGHT = 48;

interface IDetailsLayoutProps {
  addPadding?: Boolean;
  title?: string;
  toolbar?: ReactNode;
  toolbarHeight?: number;
  tabsComponent?: ReactNode;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  children?: ReactNode;
  actionButtonsComponent?: ReactNode;
}

const DetailsLayout: React.FC<IDetailsLayoutProps> = ({
  children,
  addPadding,
  title,
  toolbar,
  toolbarHeight = toolbar ? 117 : 0,
  tabsComponent,
  onBackClick,
  onCloseClick,
  actionButtonsComponent,
}) => {
  return (
    <>
      <Main
        style={{
          minHeight: `calc(100vh - ${TABS_HEIGHT}px - ${
            addPadding ? pxToRem(0) : pxToRem(16 * 2)
          })`,
        }}
      >
        <NavBar
          title={title}
          toolbar={toolbar}
          toolbarHeight={toolbarHeight}
          tabsComponent={tabsComponent}
          actionButtonsComponent={actionButtonsComponent}
          onBackClick={onBackClick}
          onCloseClick={onCloseClick}
        />
        <ColumnCentered style={{ padding: addPadding ? pxToRem(16) : 0 }}>
          {children}
        </ColumnCentered>
      </Main>
    </>
  );
};

export default DetailsLayout;

const Main = styled("div")({
  //MUI5
  //Agregar prop addPadding y que minHeight se calcule acá
  backgroundColor: "#f9f7fc",
  paddingTop: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "space-between",
});
const ColumnCentered = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  flex: 1,
});
interface INavBarProps {
  title?: string;
  toolbar?: ReactNode;
  toolbarHeight?: number;
  tabsComponent?: ReactNode;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  actionButtonsComponent?: ReactNode;
}

const NavBar: React.FC<INavBarProps> = ({
  title = "",
  toolbar,
  toolbarHeight = 0,
  tabsComponent,
  onBackClick,
  onCloseClick,
  actionButtonsComponent,
}) => {
  const isScrollTriggered = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction="down" in={!isScrollTriggered}>
        <Topbar
          style={{
            boxShadow: tabsComponent ? "none" : "0 1px 1px 0 #00000029",
            height: NAV_BAR_HEIGHT + toolbarHeight,
          }}
        >
          <Content>
            <Container>
              <LightTooltip title={"VOLVER"}>
                <IconButton
                  onClick={onBackClick}
                  disabled={onBackClick ? false : true}
                >
                  <IconFile src={backIcon} alt="Icono volver" />
                </IconButton>
              </LightTooltip>
              <TitleContainer>
                <Title>{title}</Title>
              </TitleContainer>
              {actionButtonsComponent && <div>{actionButtonsComponent}</div>}
              {!onCloseClick && <CloseIconSubstitute />}
              {onCloseClick && (
                <LightTooltip title={"CERRAR"}>
                  <IconButton onClick={onCloseClick}>
                    <IconFile src={closeIcon} alt="Icono cerrar" />
                  </IconButton>
                </LightTooltip>
              )}
            </Container>
            {toolbar}
          </Content>
        </Topbar>
      </Slide>

      {tabsComponent && (
        <Tabs
          style={{
            top: !isScrollTriggered ? NAV_BAR_HEIGHT + toolbarHeight : 0,
          }}
        >
          <ContentSection>{tabsComponent}</ContentSection>
        </Tabs>
      )}
    </>
  );
};
const IconButton = styled(MuiIconButton)({
  width: 56,
  height: 56,
});
const TitleContainer = styled("div")({ flex: 1, textAlign: "center" });
const CloseIconSubstitute = styled("div")({
  width: 56,
  height: 56,
});
const Topbar = styled("div")({
  position: "sticky",
  top: 0,
  background: "#fff",
  zIndex: 999,
  //marginBottom: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
const Content = styled("div")({
  flex: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
});

const Container = styled("div")({
  display: "flex",
  width: "100%",
});
const Title = styled(Typography)({
  fontSize: pxToRem(18),
  fontWeight: "bold",
  lineHeight: "56px",
  margin: 0,
});
Title.defaultProps = {
  variant: "h1",
  color: "primary",
};

const Tabs = styled("div")({
  position: "sticky",
  top: 0,
  background: "#fff",
  boxShadow: "0 2px 3px 0 #00000029",
  zIndex: 999,
  //marginBottom: 20,
  height: TABS_HEIGHT,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.2s ease-out",
});

const LightTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 5,
  },
}));

const IconFile = styled("img")({
  width: 18,
  height: 18,
});
