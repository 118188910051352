import React from "react";
import pxToRem from "libs/pxToRem";
import getProvincias from "components/business/provinciaSelector/getProvincias";

import { DataCard, CardContainer } from "components/styled/dataCard/DataCard";
import DataCardRow from "components/styled/dataCard/DataCardRow";
import CurrencyText from "components/styled/texts/CurrencyText";
import DataIcon from "assets/icons/ic_datos.svg";
import dateToString from "libs/dateToString";

import { SolicitudConsumoAnalisisResponse } from "api/types/originacion/services/analisisSolicitudTypes";
import { EEstadoSolicitudConsumo } from "api/types/originacion/businessTypes";

const CreditoCard: React.FC<{
  operacion?: SolicitudConsumoAnalisisResponse;
  isLoading?: boolean;
}> = ({ operacion, isLoading = false }) => {
  return (
    <CardContainer>
      <DataCard
        title="CRÉDITO"
        icon={
          <img
            src={DataIcon}
            style={{
              height: pxToRem(16.75),
              width: pxToRem(15.55),
            }}
            alt="data-icon"
          />
        }
      >
        <DataCardRow
          label="Punto de Venta"
          isLoading={isLoading}
          value={operacion?.canalNombre ? operacion.canalNombre : "-"}
          selectable
        />
        <DataCardRow
          label="Dirección (PDV)"
          isLoading={isLoading}
          value={
            operacion?.canalDomicilio || operacion?.canalCodigoPostal
              ? `${operacion.canalDomicilio} (${
                  operacion.canalCodigoPostal
                }) ${getProvinciaLabel(operacion.canalProvincia)}`
              : "-"
          }
          selectable
        />
        <DataCardRow
          label="Línea Producto"
          isLoading={isLoading}
          value={operacion?.lineaProducto ? operacion.lineaProducto : "-"}
          selectable
        />
        {(operacion?.estado === EEstadoSolicitudConsumo.En_Analisis ||
          operacion?.estado === EEstadoSolicitudConsumo.Aprobada ||
          operacion?.estado === EEstadoSolicitudConsumo.Liquidada) && (
          <>
            <DataCardRow
              label="Capital a Financiar"
              isLoading={isLoading}
              value={
                operacion?.importe !== undefined &&
                operacion.importe !== null ? (
                  <CurrencyText decimalScale={2} value={operacion.importe} />
                ) : (
                  "-"
                )
              }
              selectable
            />
            <DataCardRow
              label="Plazo"
              isLoading={isLoading}
              value={operacion?.plazo ? `${operacion.plazo} cuotas` : "-"}
              selectable
            />
            <DataCardRow
              label="TNA"
              isLoading={isLoading}
              value={operacion?.tna ? `${operacion.tna}%` : "-"}
              selectable
            />
            <DataCardRow
              label="IVA"
              isLoading={isLoading}
              value={
                operacion?.planComercial?.iva !== undefined &&
                operacion.planComercial.iva !== null
                  ? `${operacion.planComercial.iva * 100}%`
                  : "-"
              }
              selectable
            />
            <DataCardRow
              label="Cuota"
              isLoading={isLoading}
              value={
                operacion?.importeCuota !== undefined &&
                operacion.importeCuota !== null ? (
                  <CurrencyText
                    decimalScale={2}
                    value={operacion.importeCuota}
                  />
                ) : (
                  "-"
                )
              }
              selectable
            />
            <DataCardRow
              label="Primer Vencimiento"
              isLoading={isLoading}
              value={
                operacion?.primerVencimiento
                  ? dateToString(operacion.primerVencimiento)
                  : "-"
              }
              selectable
            />
          </>
        )}
      </DataCard>
    </CardContainer>
  );
};

export default CreditoCard;

const getProvinciaLabel = (provinciaId: number | undefined) => {
  const provinciasArgentinas = getProvincias();
  const prov = provinciasArgentinas.find((p) => p.value === provinciaId);
  return prov ? prov.label : "-";
};
