import React from "react";
import dateToString from "libs/dateToString";
import DataCardRow from "components/styled/dataCard/DataCardRow";
import { DataCard, CardContainer } from "components/styled/dataCard/DataCard";
import PersonIcon from "assets/icons/ic-perfil-active.svg";
import DniText from "components/styled/texts/DniText";
import CuiText from "components/styled/texts/CuiText";
import { DetallePersonaConsumo } from "api/types/originacion/services/analisisSolicitudTypes";
import styled from "@material-ui/core/styles/styled";
import CircularProgress from "@material-ui/core/CircularProgress";

const DatosPersonaCard: React.FC<{
  persona?: DetallePersonaConsumo;
  isLoading: boolean;
}> = ({ persona, isLoading = false }) => {
  return (
    <CardContainer>
      <DataCard
        title="DATOS PERSONALES"
        icon={<img src={PersonIcon} alt="person-icon" />}
      >
        {(!persona || isLoading) && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}
        {persona && !isLoading && (
          <>
            <DataCardRow
              label="Nombre"
              isLoading={isLoading}
              value={`${persona.nombre} ${persona.apellido}`}
              selectable
            />
            <DataCardRow
              label="DNI"
              isLoading={isLoading}
              value={<DniText value={persona.documento} />}
              selectable
            />
            <DataCardRow
              label="Sexo"
              isLoading={isLoading}
              value={persona?.sexo}
              selectable
            />
            <DataCardRow
              label="Cuil"
              isLoading={isLoading}
              value={<CuiText value={persona.cuil} />}
              selectable
            />
            <DataCardRow
              label="Fecha de nacimiento"
              isLoading={isLoading}
              value={dateToString(persona.fechaNacimiento)}
              selectable
            />
          </>
        )}
      </DataCard>
    </CardContainer>
  );
};

export default DatosPersonaCard;

const LoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Loading = styled(CircularProgress)({
  alignSelf: "center",
});

const CalificacionAvatarContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

// const getNacionalidadLabel = (nacionalidadId: number | undefined) => {
//   return (
//     nacionalidadesList.find((x) => x.value === nacionalidadId)?.label ?? "-"
//   );
// };
