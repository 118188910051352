import React from "react";
import pxToRem from "libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  base: ({ backgroundColor, color }: any) => ({
    backgroundColor: backgroundColor
      ? backgroundColor
      : theme.palette.primary.light,
    color: color ? color : "white",
    transform: "scale(1)",
    position: "relative",
  }),
  root: {
    display: "inline-block",
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8),
  },
}));

interface IProps {
  badgeContent: string;
  backgroundColor?: string;
  color?: string;
}

const Ellipse: React.FC<IProps> = ({
  badgeContent,
  backgroundColor,
  color,
}) => {
  const classes = useStyles({ backgroundColor, color });
  return (
    <Badge
      overlap="rectangular"
      badgeContent={badgeContent}
      classes={{ root: classes.root, badge: classes.base }}
    />
  );
};

export default Ellipse;
