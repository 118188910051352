import React, { ReactNode } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "@material-ui/core/styles/styled";
import MuiDialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiTypography from "@material-ui/core/Typography";
import pxToRem from "libs/pxToRem";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

interface IModalProps {
  opened: boolean;
  onClose: () => void;
  title?: string;
  dialogActions?: ReactNode;
  modalHeader?: ReactNode;
  withPadding?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  backgroundcolor?: string;
  children?: ReactNode;
}

const Modal = ({
  opened = false,
  onClose,
  title = "",
  dialogActions,
  withPadding = true,
  maxWidth = "sm",
  backgroundcolor = "white",
  modalHeader,
  children,
}: IModalProps) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={opened}
      TransitionComponent={Transition}
      transitionDuration={500}
      maxWidth={maxWidth}
    >
      {title.length !== 0 ? (
        <ModalTitle onClose={onClose}>{title}</ModalTitle>
      ) : (
        modalHeader
      )}
      <DialogContent
        id="dialogcontent1"
        dividers
        classes={!withPadding ? { root: classes.rootWithNoPadding } : undefined}
        backgroundcolor={backgroundcolor}
      >
        {children}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};
export default Modal;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogActions = styled(MuiDialogActions)({
  "&.MuiDialogActions-root": {
    justifyContent: "center",
    padding: 24,
  },
});

const ModalTitle = ({
  children,
  onClose,
}: {
  onClose(): void;
  children: ReactNode;
}) => {
  return (
    <DialogTitle>
      <Typography>{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const Dialog = styled(MuiDialog)({
  "&.MuiDialog-paper": {
    borderRadius: 12,
    boxShadow: "none",
    width: "100%",
  },
});
const DialogContent = withStyles((theme) => ({
  root: ({ backgroundColor }: any) => ({
    padding: pxToRem(24),
    backgroundColor: backgroundColor,
  }),
}))(MuiDialogContent);

const Typography = styled(MuiTypography)({
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  letterSpacing: 0.45,
  textAlign: "center",
  color: "#663ea8",
});

const DialogTitle = styled(MuiDialogTitle)({
  margin: 0,
  padding: pxToRem(16),
});
const useStyles = makeStyles({
  rootWithNoPadding: {
    padding: 0,
  },
});
