import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ContentLoader from "react-content-loader";

interface ITableLoading {
  columns?: number;
  rows?: number;
}
const TableLoading = ({ columns = 8, rows = 10 }: ITableLoading) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={columns}></TableCell>
        </TableRow>
        <TableRow>
          {Array.apply(null, Array(columns)).map((_, index) => (
            <TableCell key={index} align="center">
              <LoadingItem />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.apply(null, Array(rows)).map((_, index) => (
          <TableRow key={index}>
            {Array.apply(null, Array(columns)).map((_, index) => (
              <TableCell key={index} align="center">
                <LoadingItem />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default TableLoading;

const LoadingItem = () => (
  <ContentLoader
    height={20}
    width={"80%"}
    speed={1}
    backgroundColor="#f7f8ff"
    foregroundColor="#e0e0e0"
  >
    <rect x="0" y="4" rx="4" ry="4" width="90%" height="12" />
  </ContentLoader>
);
