import React from "react";
import pxToRem from "libs/pxToRem";
import getProvincias from "components/business/provinciaSelector/getProvincias";

import MuiHome from "@material-ui/icons/Home";
import { DataCard, CardContainer } from "components/styled/dataCard/DataCard";
import DataCardRow from "components/styled/dataCard/DataCardRow";

import { DomicilioPersonaConsumo } from "api/types/originacion/services/analisisSolicitudTypes";

const DomicilioCard: React.FC<{
  domicilio?: DomicilioPersonaConsumo;
  isLoading?: boolean;
}> = ({ domicilio, isLoading = false }) => {
  return (
    <CardContainer>
      <DataCard
        title="DOMICILIO"
        icon={
          <MuiHome
            style={{
              color: "#9e9e9e",
              height: pxToRem(18.75),
              width: pxToRem(17.55),
            }}
          />
        }
      >
        <DataCardRow
          label="Dirección"
          isLoading={isLoading}
          value={
            domicilio?.calle ||
            domicilio?.domicilioNro ||
            domicilio?.piso ||
            domicilio?.dpto
              ? `${domicilio.calle || ""} ${domicilio.domicilioNro || ""} ${
                  domicilio.piso || ""
                } ${domicilio.dpto || ""}`.trim()
              : "-"
          }
          selectable
        />
        <DataCardRow
          label="Localidad"
          isLoading={isLoading}
          value={domicilio?.localidad ? domicilio.localidad.toTitleCase() : "-"}
          selectable
        />
        <DataCardRow
          label="Provincia"
          isLoading={isLoading}
          value={getProvinciaLabel(domicilio?.provincia)}
          selectable
        />
        <DataCardRow
          label="C.P."
          isLoading={isLoading}
          value={domicilio?.codigoPostal ? domicilio.codigoPostal : "-"}
          selectable
        />
      </DataCard>
    </CardContainer>
  );
};

export default DomicilioCard;

const getProvinciaLabel = (provinciaId: number | undefined) => {
  const provinciasArgentinas = getProvincias();
  const prov = provinciasArgentinas.find((p) => p.value === provinciaId);
  return prov ? prov.label : "-";
};
