import React from "react";
import { useMutation, QueryKey, useQueryClient } from "@tanstack/react-query";
import { solicitudes } from "api/solicitudes";
import {
  SolicitudAnalisisResponse,
  DesistirSolicitudPrendarioRequest,
  SolicitudConsumoAnalisisResponse,
} from "api/types/originacion/services/analisisSolicitudTypes";

import { EEstadoSolicitudConsumo } from "api/types/originacion/businessTypes";

import useToken from "components/hooks/useToken";

import DesistirSolicitud from "./actions/DesistirSolicitud";

interface ISolicitudAnalisisActions {
  solicitud: SolicitudConsumoAnalisisResponse;
}

const SolicitudRevisionActions = ({ solicitud }: ISolicitudAnalisisActions) => {
  const { desistirCredito, mutationLoading } = useAnalisisActions();

  const canDesistirOrRechazar =
    solicitud.estado !== EEstadoSolicitudConsumo.Incompleta &&
    solicitud.estado !== EEstadoSolicitudConsumo.Liquidada &&
    solicitud.estado !== EEstadoSolicitudConsumo.Desistida &&
    solicitud.estado !== EEstadoSolicitudConsumo.Rechazada;

  const handleDesistir = (comentario: string) => {
    desistirCredito({ comentario, id: solicitud.id });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      {canDesistirOrRechazar && (
        <>
          <DesistirSolicitud
            handleDesistir={handleDesistir}
            isLoading={mutationLoading}
          />
        </>
      )}
    </div>
  );
};
export default SolicitudRevisionActions;

const useAnalisisActions = () => {
  const token = useToken();
  const queryClient = useQueryClient();

  //desistir
  const desistirCreditoRequest = async (
    request: DesistirSolicitudPrendarioRequest
  ) => {
    if (!token) throw new Error("Must be authenticated");
    await solicitudes(token).desistir(request);
  };
  const { mutate: desistirCredito, isLoading: isLoadingDesistir } = useMutation(
    desistirCreditoRequest,
    {
      onMutate: ({ id }) =>
        updateCacheSolicitud(id, EEstadoSolicitudConsumo.Desistida),
      onSuccess: (_, { id }) => invalidateCacheSolicitud(id),
    }
  );

  const updateCacheSolicitud = (
    id: number,
    estado: EEstadoSolicitudConsumo
  ) => {
    const cacheKey = ["detalleSolicitud", { id: `${id}` }] as QueryKey;
    const prevSolicitud = queryClient.getQueryData(
      cacheKey
    ) as SolicitudAnalisisResponse;
    queryClient.setQueryData(cacheKey, {
      ...prevSolicitud,
      estadoSolicitud: estado,
    });
  };

  const invalidateCacheSolicitud = (id: number) => {
    const cacheKey = ["detalleSolicitud", { id: `${id}` }] as QueryKey;
    queryClient.invalidateQueries(cacheKey);
  };

  return {
    desistirCredito,
    mutationLoading: isLoadingDesistir,
  };
};
