import { useHistory, useParams } from "react-router-dom";

const useSolicitudIdParam = (redirectOnFail = true) => {
    const history = useHistory();
    const { solicitudId } = useParams<{ solicitudId: string }>();
    const resolvedValue = Number(solicitudId);
    if (redirectOnFail && !resolvedValue)
        history.push("/");

    return resolvedValue;
}

export default useSolicitudIdParam