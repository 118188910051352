import React, { ReactNode } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import pxToRem from "libs/pxToRem";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link/Link";
/*
    orange: {backgroundColor: "#ffeece",color: "#f17f00"},zeplin> color: #f77200
    yellow: { text: "#ee9d00", background: "#fff7cc" },
    green: { text: "#069661", background: "#d2f6e2" },
    red: { text: "#dd1111", background: "#ffe0e0" },
    blue: { text: "#009bba", background: "#c7f2fa" }
*/

const OrangeAlert = withStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#ffeece",
  },
  message: {
    fontSize: pxToRem(16),
    fontWeight: "bold !important" as any,
    color: "#f77200",
  },
  icon: {
    color: "#f77200 !important" as any,
    fontSize: 25,
  },
}))(Alert);

const GreenAlert = withStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#d2f6e2",
  },
  message: {
    fontSize: pxToRem(16),
    fontWeight: "bold !important" as any,
    color: "#069661",
  },
  icon: {
    color: "#069661 !important" as any,
    fontSize: 25,
  },
}))(Alert);

const BlueAlert = withStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#c7f2fa",
  },
  message: {
    fontSize: pxToRem(16),
    fontWeight: "bold !important" as any,
    color: "#009bba",
  },
  icon: {
    color: "#009bba !important" as any,
    fontSize: 25,
  },
}))(Alert);

const YellowAlert = withStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#fff7cc",
  },
  message: {
    fontSize: pxToRem(16),
    fontWeight: "bold !important" as any,
    color: "#ee9d00",
  },
  icon: {
    color: "#ee9d00 !important" as any,
    fontSize: 25,
  },
}))(Alert);

const RedAlert = withStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#ffe0e0",
  },
  message: {
    fontSize: pxToRem(16),
    fontWeight: "bold !important" as any,
    color: "#dd1111",
  },
  icon: {
    color: "#dd1111 !important" as any,
    fontSize: 25,
  },
}))(Alert);

export enum EAlertType {
  warning = 0,
  info,
  success,
  error,
  other,
}

interface IAlertSectionProps {
  alertType: EAlertType;
  variant?: any;
  icon?: ReactNode;
  title?: string;
  inlineLink?: string;
  rightLink?: string;
  onClick?: () => void;
  onClose?: () => void;
  marginBottom?: number;
  children?: React.ReactNode;
}

const AlertSection: React.FC<IAlertSectionProps> = ({
  alertType,
  variant,
  icon,
  inlineLink,
  rightLink,
  onClick,
  onClose,
  children,
  marginBottom,
}) => {
  const theme = useTheme();
  switch (alertType) {
    case EAlertType.warning:
      return (
        <OrangeAlert
          variant={variant}
          severity="warning"
          icon={icon}
          onClick={onClick}
          onClose={onClose}
          action={<u>{rightLink}</u>}
          style={marginBottom ? { marginBottom } : undefined}
        >
          {children}
          <Link
            component="button"
            onClick={onClick}
            style={{
              fontFamily: theme.typography.fontFamily,
              verticalAlign: "top",
              marginLeft: pxToRem(4),
              fontSize: pxToRem(16),
              fontWeight: "bold",
              color: "#f77200",
              textDecoration: "underline",
            }}
          >
            {inlineLink}
          </Link>
        </OrangeAlert>
      );
    case EAlertType.success:
      return (
        <GreenAlert
          variant={variant}
          severity="success"
          icon={icon}
          onClick={onClick}
          onClose={onClose}
          action={<u>{rightLink}</u>}
          style={marginBottom ? { marginBottom } : undefined}
        >
          {children}
          <Link
            component="button"
            onClick={onClick}
            style={{
              fontFamily: theme.typography.fontFamily,
              verticalAlign: "top",
              marginLeft: pxToRem(4),
              fontSize: pxToRem(16),
              fontWeight: "bold",
              color: "#069661",
              textDecoration: "underline",
            }}
          >
            {inlineLink}
          </Link>
        </GreenAlert>
      );
    case EAlertType.info:
      return (
        <BlueAlert
          variant={variant}
          severity="info"
          icon={icon}
          onClick={onClick}
          onClose={onClose}
          action={<u>{rightLink}</u>}
          style={marginBottom ? { marginBottom } : undefined}
        >
          {children}
          <Link
            component="button"
            onClick={onClick}
            style={{
              fontFamily: theme.typography.fontFamily,
              verticalAlign: "top",
              marginLeft: pxToRem(4),
              fontSize: pxToRem(16),
              fontWeight: "bold",
              color: "#009bba",
              textDecoration: "underline",
            }}
          >
            {inlineLink}
          </Link>
        </BlueAlert>
      );
    case EAlertType.error:
      return (
        <RedAlert
          variant={variant}
          severity="warning"
          icon={icon}
          onClick={onClick}
          onClose={onClose}
          action={<u>{rightLink}</u>}
          style={marginBottom ? { marginBottom } : undefined}
        >
          {children}
          <Link
            component="button"
            onClick={onClick}
            style={{
              fontFamily: theme.typography.fontFamily,
              verticalAlign: "top",
              marginLeft: pxToRem(4),
              fontSize: pxToRem(16),
              fontWeight: "bold",
              color: "#dd1111",
              textDecoration: "underline",
            }}
          >
            {inlineLink}
          </Link>
        </RedAlert>
      );
    default:
      return (
        <YellowAlert
          variant={variant}
          severity="error"
          icon={icon}
          onClick={onClick}
          onClose={onClose}
          action={<u>{rightLink}</u>}
          style={marginBottom ? { marginBottom } : undefined}
        >
          {children}
          <Link
            component="button"
            onClick={onClick}
            style={{
              fontFamily: theme.typography.fontFamily,
              verticalAlign: "top",
              marginLeft: pxToRem(4),
              fontSize: pxToRem(16),
              fontWeight: "bold",
              color: "#ee9d00",
              textDecoration: "underline",
            }}
          >
            {inlineLink}
          </Link>
        </YellowAlert>
      );
  }
};

export default AlertSection;
