import React, { useState } from "react";
import pxToRem from "libs/pxToRem";
import Typography from "@material-ui/core/Typography";
import CollapsableSection from "components/styled/collapsableSection/CollapsableSection";
import {
  PrimaryLink,
  MutedLink,
} from "components/styled/linkButton/LinkButton";
import ContentSection from "components/styled/contentSection/ContentSection";
import AlertSection, {
  EAlertType,
} from "components/styled/alerts/AlertSection";
import EstadoSolicitudLabel from "components/business/estadoSolicitudLabel/EstadoSolicitudLabel";
import Modal from "components/styled/modal/Modal";
import CheckIcon from "@material-ui/icons/Check";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import { mediaBreakpoints } from "assets/mgTheme";
import {
  OperacionRelacionada,
  SolicitudConsumoAnalisisResponse,
} from "api/types/originacion/services/analisisSolicitudTypes";

import DomicilioCard from "./cards/DomicilioCard";
import CreditoCard from "./cards/CreditoCard";
import InformesCard from "./cards/InformesCard";
import styled from "@material-ui/core/styles/styled";
import DatosPersonaCard from "./cards/DatosPersonalesCard";
import ContactoCard from "./cards/ContactoCard";
import { EEstadoSolicitudConsumo } from "api/types/originacion/businessTypes";

interface ISolicitudDetalle {
  solicitud?: SolicitudConsumoAnalisisResponse;
  isLoading: boolean;
}
const SolicitudDetalleConsumo = ({
  solicitud,
  isLoading,
}: ISolicitudDetalle) => {
  const titular = solicitud?.titular;
  const domicilio = titular?.domicilio;

  return (
    <ContentSection style={{ flexGrow: 1 }}>
      <Main>
        <CollapsableSection isLoading={isLoading} title={"DATOS DEL TITULAR"}>
          {titular && (
            <>
              <Column>
                <DatosPersonaCard isLoading={isLoading} persona={titular} />
                {/* <InformesCard isLoading={isLoading} solicitud={solicitud} /> */}
              </Column>
              <Column>
                {(solicitud?.estado === EEstadoSolicitudConsumo.En_Analisis ||
                  solicitud?.estado === EEstadoSolicitudConsumo.Aprobada ||
                  solicitud?.estado === EEstadoSolicitudConsumo.Liquidada) && (
                  <DomicilioCard isLoading={isLoading} domicilio={domicilio} />
                )}
                <ContactoCard
                  isLoading={isLoading}
                  contacto={titular}
                  estadoSolicitud={solicitud?.estado}
                />
              </Column>
            </>
          )}
        </CollapsableSection>

        <CollapsableSection isLoading={isLoading} title="DATOS DE LA OPERACIÓN">
          {titular && (
            <>
              <Column>
                <CreditoCard isLoading={isLoading} operacion={solicitud} />
              </Column>
            </>
          )}
        </CollapsableSection>
      </Main>
    </ContentSection>
  );
};

export default SolicitudDetalleConsumo;

const Main = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  minWidth: "70vw",
  maxWidth: "100vw",
  width: "100%",
  alignSelf: "center",
});
const Column = styled("div")({
  [mediaBreakpoints.mobile]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  [mediaBreakpoints.desktop]: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
});
const ListItemText = styled(MuiListItemText)({
  "& .MuiListItemText-primary": {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "left",
    color: "#757575",
  },
});

interface IOperacionesRelacionadasModal {
  nombre: string;
  operaciones?: OperacionRelacionada[];
}
const OperacionesRelacionadasModal = ({
  nombre,
  operaciones,
}: IOperacionesRelacionadasModal) => {
  const [isOpen, setIsOpen] = useState(false);
  const [amountOperacionesToShow, setAmountOperacionesToShow] = useState(5);
  const topOperaciones = operaciones?.slice(0, amountOperacionesToShow);
  return (
    <>
      {operaciones && operaciones.length > 0 && (
        <>
          <AlertSection
            alertType={EAlertType.warning}
            onClick={() => {
              setIsOpen(true);
            }}
            inlineLink="Más info"
          >
            Persona relacionada con otras operaciones.
          </AlertSection>

          <Modal
            opened={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            title="Más información"
            maxWidth="sm"
            dialogActions={
              <PrimaryLink
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Volver
              </PrimaryLink>
            }
          >
            <OpRelacionadasTitle>{nombre} aparece como:</OpRelacionadasTitle>
            <List dense style={{ maxHeight: pxToRem(250) }}>
              {topOperaciones?.map((operacion, index) => (
                <ListItem key={`operacion-${index}`}>
                  <ListItemIcon>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <strong>{operacion.tipoPersona}</strong> en la
                    operación&nbsp;
                    <strong>#{operacion.solicitudId}</strong> en estado
                    &nbsp;&nbsp;
                    <EstadoSolicitudLabel
                      estadoSolicitud={operacion.estadoSolicitud}
                    />
                  </ListItemText>
                </ListItem>
              ))}
              {operaciones && operaciones.length > amountOperacionesToShow && (
                <OpRelacionadasText>
                  Y está relacionado con otras{" "}
                  <strong>
                    {operaciones.length - amountOperacionesToShow}{" "}
                  </strong>
                  operaciones.{"  "}
                  <MutedLink
                    onClick={() =>
                      setAmountOperacionesToShow(amountOperacionesToShow + 5)
                    }
                  >
                    Ver más
                  </MutedLink>
                </OpRelacionadasText>
              )}
            </List>
          </Modal>
        </>
      )}
    </>
  );
};

const OpRelacionadasText = styled(Typography)({
  fontSize: pxToRem(16),
  lineHeight: 1.38,
  textAlign: "left",
  color: "#757575",
});
const OpRelacionadasTitle = styled(Typography)({
  color: "#424242",
  fontSize: pxToRem(20),
  fontWeight: "bold",
  lineHeight: 1.7,
  textAlign: "left",
});
const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: 30,
});
