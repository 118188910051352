import React from "react";
import NumberFormat from "react-number-format";

interface ICuiText {
  value?: number | string;
  [key: string]: any;
}

const CuiText = ({ value = "", ...otherProps }:ICuiText) => {
  return (
    <NumberFormat
      id="cui"
      displayType={"text"}
      value={value}
      isNumericString
      thousandSeparator={"."}
      decimalSeparator={","}
      format={"##-########-#"}
      decimalScale={0}
      {...otherProps}
    />
  );
};

export default CuiText;
