import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { ESituacionOperacion } from "api/types/originacion/businessTypes";

const YellowLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff7cc",
    color: "#ee9d00",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const RedLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffe0e0",
    color: "#dd1111",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const BlueLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#c7f2fa",
    color: "#009bba",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const GreenLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#d2f6e2",
    color: "#069661",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const OrangeLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffeece",
    color: "#f17f00",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const EstadoSolicitudLabel: React.FC<{
  estadoSolicitud: ESituacionOperacion;
}> = ({ estadoSolicitud }) => {
  switch (estadoSolicitud) {
    case ESituacionOperacion.Rechazada:
    case ESituacionOperacion.Rechazada_Definitivamente:
      return <RedLabel label="Rechazada" />;
    case ESituacionOperacion.En_Analisis:
      return <YellowLabel label="En Análisis" />;
    case ESituacionOperacion.Desistida:
    case ESituacionOperacion.Desistida_por_tiempo:
      return <RedLabel label="Desistida" />;
    case ESituacionOperacion.Aprobada:
      return <GreenLabel label="Aprobada" />;
    case ESituacionOperacion.Informes_Pendientes:
      return <BlueLabel label="Informes Pendientes" />;
    case ESituacionOperacion.En_Espera_Informes_Riesgo_Vehiculo:
      return <OrangeLabel label="Inf. Riesgo Vehiculo Pendientes" />;
    case ESituacionOperacion.Confeccion_Solicitada:
      return <YellowLabel label="Confección Solicitada" />;
    case ESituacionOperacion.Prenda_Confeccionada:
      return <GreenLabel label="Prenda Confeccionada" />;
    case ESituacionOperacion.Liquidacion_solicitada:
      return <YellowLabel label="Liquidación Solicitada" />;
    case ESituacionOperacion.Carga_Incompleta:
    case ESituacionOperacion.No_Apto_Habilitado:
      return <BlueLabel label="Incompleta" />;
    case ESituacionOperacion.Liquidada:
      return <OrangeLabel label="Liquidada" />;
    default:
      return <YellowLabel label="En Análisis" />;
  }
};

export default EstadoSolicitudLabel;
