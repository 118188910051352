import React from "react";
import { DataCard, CardContainer } from "components/styled/dataCard/DataCard";
import DataCardRow from "components/styled/dataCard/DataCardRow";
import ContactIcon from "assets/icons/ic-contacto-active.svg";
import getPhoneFormat from "libs/getPhoneFormat";
import { DetallePersonaConsumo } from "api/types/originacion/services/analisisSolicitudTypes";
import { EEstadoSolicitudConsumo } from "api/types/originacion/businessTypes";

const ContactoCard: React.FC<{
  estadoSolicitud?: EEstadoSolicitudConsumo;
  contacto?: DetallePersonaConsumo;
  isLoading?: boolean;
}> = ({ contacto, estadoSolicitud, isLoading = false }) => {
  return (
    <CardContainer>
      <DataCard
        title="TELÉFONOS"
        icon={<img src={ContactIcon} alt="contact-icon" />}
      >
        <DataCardRow
          label="Celular"
          isLoading={isLoading}
          value={contacto?.telefono ? getPhoneFormat(contacto.telefono) : "-"}
          selectable
        />
        {(estadoSolicitud === EEstadoSolicitudConsumo.En_Analisis ||
          estadoSolicitud === EEstadoSolicitudConsumo.Aprobada ||
          estadoSolicitud === EEstadoSolicitudConsumo.Liquidada) && (
          <DataCardRow
            label="Email"
            isLoading={isLoading}
            value={contacto?.email ? contacto.email : "-"}
            selectable
          />
        )}
      </DataCard>
    </CardContainer>
  );
};

export default ContactoCard;
