import React from "react";
import List from "@material-ui/core/List";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiCard from "@material-ui/core/Card";
import MuiCardHeader from "@material-ui/core/CardHeader";
import pxToRem from "libs/pxToRem";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MuiDeleteIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";
import editIcon from "assets/icons/btn-graphic_small.svg";
import deleteIcon from "assets/icons/ic_square-delete_24px.svg";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "@material-ui/core/styles/styled";

const DataListItem = styled(MuiListItemText)({
  "& .MuiListItemText-primary": {
    paddingLeft: 0,
    fontSize: pxToRem(16),
    fontWeight: 600,
    color: "#9e9e9e",
    marginRight: pxToRem(15),
  },
  "& .MuiListItemText-secondary": {
    textAlign: "right",
    fontSize: pxToRem(16),
    fontWeight: "bold",
    color: "#424242",
    wordWrap: "break-word",
  },
});

const Card = styled(MuiCard)({
    width: "100%",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: pxToRem(1),
});

const CardHeader = styled(MuiCardHeader)({
    paddingLeft: pxToRem(20),
    paddingRight: pxToRem(20),
    paddingBottom: pxToRem(8),
  "& .MuiCardHeader-avatar": {
    display: "flex",
    marginRight: pxToRem(10),
  },
  "& .MuiCardHeader-title": {
    fontSize: `${pxToRem(14)} !important`,
    fontWeight: "bold !important" as any,
    lineHeight: 1.36,
    color: "#9e9e9e",
  },
  "& .MuiCardHeader-action": {
    alignSelf: "auto",
    marginTop: "inherit",
    marginRight: "inherit",
  },
});

const DataCard: React.FC<{
  title: React.ReactNode;
  icon?: React.ReactNode;
  marginBottom?: number;
  action?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ children, title, icon, marginBottom, action }) => {
  return (
    <Card style={{ marginBottom }}>
      <CardHeader avatar={icon} action={action} title={title} />
      <div
        style={{
          padding: "0 20px 0 20px",
        }}
      >
        <List>{children}</List>
      </div>
    </Card>
  );
};

interface Event<T> {
  target: {
    value: T;
  };
}

const DataError: React.FC<{
  message?: string;
}> = ({ message = "" }) => {
  return (
    <LightTooltip title={message} placement="top">
      <IconButton size="small" color="primary" edge="end">
        <InfoIcon
          style={{
            color: "#f77221",
          }}
        />
      </IconButton>
    </LightTooltip>
  );
};

const DataCheckbox: React.FC<{
  onClick?: (event: Event<boolean>) => void;
  value?: boolean;
  size?: "small" | "medium" | undefined;
  edge?: "start" | "end" | undefined;
}> = ({ onClick, value = false, size = "small", edge = "end" }) => {
  return (
    <IconButton
      size={size}
      edge={edge}
      color="primary"
      onClick={(event) => {
        if (onClick) {
          onClick({
            target: { value: value },
          });
        }
      }}
    >
      {value && (
        <CheckCircleIcon
          style={{
            color: "#009ebe",
          }}
        />
      )}
      {!value && (
        <CheckCircleIcon
          style={{
            color: "#bebebe",
          }}
        />
      )}
    </IconButton>
  );
};

const EditButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  return (
    <IconButton size="small" color="primary" edge="end" onClick={onClick}>
      <img src={editIcon} alt="" />
    </IconButton>
  );
};

const DeleteButton: React.FC<{
  onClick?: () => void;
  value?: boolean;
}> = ({ onClick, value = false }) => {
  return (
    <IconButton size="small" color="primary" edge="end" onClick={onClick}>
      {!value && <img src={deleteIcon} alt="" style={{ height: 24 }} />}
      {value && <DeleteIcon />}
    </IconButton>
  );
};

const DeleteIcon = styled(MuiDeleteIcon)({
  color: "#d64c4c",
  height: 24,
});
const EmptySpaceButton = styled("div")({
  width: 30,
  height: 30,
  marginRight: -3,
});
const CardContainer = styled("div")({
  padding: pxToRem(8),
  display: "flex",
  flexGrow: 1,
});
const LightTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 5,
  },
}));

export {
  CardContainer,
  DataCard,
  DataListItem,
  DataCheckbox,
  EditButton,
  DeleteButton,
  EmptySpaceButton,
  DataError,
};
