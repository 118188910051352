const getProvincias = () => provinciasArgentinas;

export default getProvincias;

export interface Provincia{
  value: number;
  label: string;
}

const provinciasArgentinas: Provincia[] = [
  {
    value: 1,
    label: "Capital Federal",
  },
  {
    value: 2,
    label: "Buenos Aires",
  },
  {
    value: 3,
    label: "Córdoba",
  },
  {
    value: 4,
    label: "Tucuman",
  },
  {
    value: 5,
    label: "Misiones",
  },
  {
    value: 6,
    label: "Corrientes",
  },
  {
    value: 7,
    label: "Entre Ríos",
  },
  {
    value: 8,
    label: "Chaco",
  },
  {
    value: 9,
    label: "Formosa",
  },
  {
    value: 10,
    label: "Jujuy",
  },
  {
    value: 11,
    label: "Salta",
  },
  {
    value: 12,
    label: "Santiago del Estero",
  },
  {
    value: 13,
    label: "La Pampa",
  },
  {
    value: 14,
    label: "Catamarca",
  },
  {
    value: 15,
    label: "La Rioja",
  },
  {
    value: 16,
    label: "San Juan",
  },
  {
    value: 17,
    label: "Mendoza",
  },
  {
    value: 18,
    label: "San Luis",
  },
  {
    value: 20,
    label: "Santa Fe",
  },
  {
    value: 21,
    label: "Neuquén",
  },
  {
    value: 22,
    label: "Río Negro",
  },
  {
    value: 23,
    label: "Chubut",
  },
  {
    value: 24,
    label: "Santa Cruz",
  },
  {
    value: 25,
    label: "Tierra del Fuego",
  },
].sort((a, b) => {
  const provA = a.label.toUpperCase();
  const provB = b.label.toUpperCase();

  let comparison = 0;
  if (provA > provB) {
    comparison = 1;
  } else if (provA < provB) {
    comparison = -1;
  }
  return comparison;
});
