import styled from "@material-ui/core/styles/styled";
import MuiLink from "@material-ui/core/Link";
import pxToRem from "libs/pxToRem";

const PrimaryLink = styled(MuiLink)(({ theme }) => ({
  "&.MuiLink-root": {
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.5,
    color: theme.palette.primary.light,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}));

const MutedLink = styled(MuiLink)({
  "&.MuiLink-root": {
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.5,
    color: "#bdbdbd",
    cursor: "pointer",
    "&:hover": {
      color: "#999999",
    },
  },
});

export { PrimaryLink, MutedLink };
