interface AreaCode {
  Code: string;
  Description: string;
}

type AreaCodeMap = Record<string, AreaCode>;

export const getArea = (number: string): AreaCode | null => {
  let minAreaCodeLength = 3;
  let areaFounded: AreaCode | null = null;

  for (let i = number.length; i >= minAreaCodeLength; i--) {
    const areaCode = number.substring(0, i);

    areaFounded = CODIGOS_DE_AREA_DE_ARGENTINA[areaCode] || null;

    if (areaFounded) return areaFounded;
  }

  return areaFounded;
};

export default getArea;

export const CODIGOS_DE_AREA_DE_ARGENTINA: AreaCodeMap = {
  "03894": { Code: "03894", Description: "Tucumán" },
  "03892": { Code: "03892", Description: "Tucumán" },
  "03891": { Code: "03891", Description: "Tucumán" },
  "03888": { Code: "03888", Description: "Jujuy" },
  "03887": { Code: "03887", Description: "Jujuy" },
  "03886": { Code: "03886", Description: "Jujuy" },
  "03885": { Code: "03885", Description: "Jujuy / Salta" },
  "0388": { Code: "0388", Description: "Jujuy" },
  "03878": { Code: "03878", Description: "Salta" },
  "03877": { Code: "03877", Description: "Salta / Chaco" },
  "03876": { Code: "03876", Description: "Salta" },
  "03873": { Code: "03873", Description: "Salta" },
  "0387": { Code: "0387", Description: "Salta" },
  "03869": { Code: "03869", Description: "Tucumán" },
  "03868": { Code: "03868", Description: "Salta" },
  "03867": { Code: "03867", Description: "Tucumán" },
  "03865": { Code: "03865", Description: "Tucumán" },
  "03863": { Code: "03863", Description: "Tucumán" },
  "03862": { Code: "03862", Description: "Tucumán" },
  "03861": { Code: "03861", Description: "Santiago del Estero" },
  "03858": { Code: "03858", Description: "Santiago del Estero" },
  "03857": { Code: "03857", Description: "Santiago del Estero" },
  "03856": { Code: "03856", Description: "Santiago del Estero" },
  "03855": { Code: "03855", Description: "Santiago del Estero" },
  "03854": { Code: "03854", Description: "Santiago del Estero" },
  "0385": { Code: "0385", Description: "Santiago del Estero" },
  "03846": { Code: "03846", Description: "Santiago del Estero" },
  "03845": { Code: "03845", Description: "Santiago del Estero" },
  "03844": { Code: "03844", Description: "Santiago del Estero" },
  "03843": { Code: "03843", Description: "Santiago del Estero" },
  "03841": { Code: "03841", Description: "Santiago del Estero" },
  "03838": { Code: "03838", Description: "Catamarca" },
  "03837": { Code: "03837", Description: "Catamarca" },
  "03835": { Code: "03835", Description: "Catamarca" },
  "03832": { Code: "03832", Description: "Catamarca / Santiago del Estero" },
  "0383": { Code: "0383", Description: "Catamarca" },
  "03827": { Code: "03827", Description: "La Rioja" },
  "03826": { Code: "03826", Description: "La Rioja" },
  "03825": { Code: "03825", Description: "La Rioja" },
  "03821": { Code: "03821", Description: "La Rioja" },
  "0381": { Code: "0381", Description: "Tucumán" },
  "0380": { Code: "0380", Description: "La Rioja" },
  "0379": { Code: "0379", Description: "Corrientes" },
  "03786": { Code: "03786", Description: "Corrientes" },
  "03782": { Code: "03782", Description: "Corrientes" },
  "03781": { Code: "03781", Description: "Corrientes" },
  "03777": { Code: "03777", Description: "Corrientes" },
  "03775": { Code: "03775", Description: "Corrientes" },
  "03774": { Code: "03774", Description: "Corrientes" },
  "03773": { Code: "03773", Description: "Corrientes" },
  "03772": { Code: "03772", Description: "Corrientes" },
  "0376": { Code: "0376", Description: "Misiones" },
  "03758": { Code: "03758", Description: "Misiones / Corrientes" },
  "03757": { Code: "03757", Description: "Misiones" },
  "03756": { Code: "03756", Description: "Corrientes" },
  "03755": { Code: "03755", Description: "Misiones" },
  "03754": { Code: "03754", Description: "Misiones" },
  "03751": { Code: "03751", Description: "Misiones" },
  "03743": { Code: "03743", Description: "Misiones" },
  "03741": { Code: "03741", Description: "Misiones" },
  "03735": { Code: "03735", Description: "Chaco" },
  "03734": { Code: "03734", Description: "Chaco" },
  "03731": { Code: "03731", Description: "Chaco" },
  "03725": { Code: "03725", Description: "Chaco" },
  "03721": { Code: "03721", Description: "Chaco" },
  "03718": { Code: "03718", Description: "Formosa" },
  "03716": { Code: "03716", Description: "Formosa" },
  "03715": { Code: "03715", Description: "Formosa / Chaco" },
  "03711": { Code: "03711", Description: "Formosa" },
  "0370": { Code: "0370", Description: "Formosa" },
  "0364": { Code: "0364", Description: "Chaco" },
  "0362": { Code: "0362", Description: "Chaco" },
  "03585": { Code: "03585", Description: "Córdoba" },
  "03584": { Code: "03584", Description: "Córdoba" },
  "03583": { Code: "03583", Description: "Córdoba" },
  "03582": { Code: "03582", Description: "Córdoba / San Luis" },
  "0358": { Code: "0358", Description: "Córdoba" },
  "03576": { Code: "03576", Description: "Córdoba" },
  "03575": { Code: "03575", Description: "Córdoba" },
  "03574": { Code: "03574", Description: "Córdoba" },
  "03573": { Code: "03573", Description: "Córdoba" },
  "03572": { Code: "03572", Description: "Córdoba" },
  "03571": { Code: "03571", Description: "Córdoba" },
  "03564": { Code: "03564", Description: "Córdoba / Santa Fe" },
  "03563": { Code: "03563", Description: "Córdoba" },
  "03562": { Code: "03562", Description: "Córdoba / Santa Fe" },
  "03549": { Code: "03549", Description: "Córdoba" },
  "03548": { Code: "03548", Description: "Córdoba" },
  "03547": { Code: "03547", Description: "Córdoba" },
  "03546": { Code: "03546", Description: "Córdoba" },
  "03544": { Code: "03544", Description: "Córdoba" },
  "03543": { Code: "03543", Description: "Córdoba" },
  "03542": { Code: "03542", Description: "Córdoba" },
  "03541": { Code: "03541", Description: "Córdoba" },
  "03537": { Code: "03537", Description: "Córdoba" },
  "03533": { Code: "03533", Description: "Córdoba" },
  "03532": { Code: "03532", Description: "Córdoba" },
  "0353": { Code: "0353", Description: "Córdoba" },
  "03525": { Code: "03525", Description: "Córdoba" },
  "03524": { Code: "03524", Description: "Córdoba" },
  "03522": { Code: "03522", Description: "Córdoba" },
  "03521": { Code: "03521", Description: "Córdoba" },
  "0351": { Code: "0351", Description: "Córdoba" },
  "03498": { Code: "03498", Description: "Santa Fe" },
  "03497": { Code: "03497", Description: "Santa Fe" },
  "03496": { Code: "03496", Description: "Santa Fe" },
  "03493": { Code: "03493", Description: "Santa Fe" },
  "03492": { Code: "03492", Description: "Santa Fe" },
  "03491": { Code: "03491", Description: "Santa Fe / Santiago del Estero" },
  "03489": { Code: "03489", Description: "Buenos Aires" },
  "03487": { Code: "03487", Description: "Buenos Aires" },
  "03483": { Code: "03483", Description: "Santa Fe" },
  "03482": { Code: "03482", Description: "Santa Fe" },
  "0348": { Code: "0348", Description: "Buenos Aires" },
  "03476": { Code: "03476", Description: "Santa Fe" },
  "03472": { Code: "03472", Description: "Córdoba" },
  "03471": { Code: "03471", Description: "Santa Fe" },
  "03469": { Code: "03469", Description: "Santa Fe" },
  "03468": { Code: "03468", Description: "Córdoba / Santa Fe" },
  "03467": { Code: "03467", Description: "Córdoba / Santa Fe" },
  "03466": { Code: "03466", Description: "Santa Fe" },
  "03465": { Code: "03465", Description: "Santa Fe" },
  "03464": { Code: "03464", Description: "Santa Fe" },
  "03463": { Code: "03463", Description: "Córdoba" },
  "03462": { Code: "03462", Description: "Santa Fe" },
  "03460": { Code: "03460", Description: "Santa Fe" },
  "03458": { Code: "03458", Description: "Entre Ríos" },
  "03456": { Code: "03456", Description: "Entre Ríos" },
  "03455": { Code: "03455", Description: "Entre Ríos" },
  "03454": { Code: "03454", Description: "Entre Ríos" },
  "0345": { Code: "0345", Description: "Entre Ríos" },
  "03447": { Code: "03447", Description: "Entre Ríos" },
  "03446": { Code: "03446", Description: "Entre Ríos" },
  "03445": { Code: "03445", Description: "Entre Ríos" },
  "03444": { Code: "03444", Description: "Entre Ríos" },
  "03442": { Code: "03442", Description: "Entre Ríos" },
  "03438": { Code: "03438", Description: "Entre Ríos" },
  "03437": { Code: "03437", Description: "Entre Ríos" },
  "03436": { Code: "03436", Description: "Entre Ríos" },
  "03435": { Code: "03435", Description: "Entre Ríos" },
  "0343": { Code: "0343", Description: "Entre Ríos" },
  "0342": { Code: "0342", Description: "Santa Fe" },
  "0341": { Code: "0341", Description: "Santa Fe" },
  "03409": { Code: "03409", Description: "Santa Fe" },
  "03408": { Code: "03408", Description: "Santa Fe" },
  "03407": { Code: "03407", Description: "Buenos Aires" },
  "03406": { Code: "03406", Description: "Santa Fe" },
  "03405": { Code: "03405", Description: "Santa Fe" },
  "03404": { Code: "03404", Description: "Santa Fe" },
  "03402": { Code: "03402", Description: "Santa Fe" },
  "03401": { Code: "03401", Description: "Santa Fe" },
  "03400": { Code: "03400", Description: "Santa Fe" },
  "03388": { Code: "03388", Description: "Buenos Aires" },
  "03387": { Code: "03387", Description: "Córdoba" },
  "03385": { Code: "03385", Description: "Córdoba" },
  "03382": { Code: "03382", Description: "Santa Fe / Buenos Aires / Córdoba" },
  "0336": { Code: "0336", Description: "Buenos Aires" },
  "03329": { Code: "03329", Description: "Buenos Aires" },
  "03327": { Code: "03327", Description: "Buenos Aires" },
  "0299": { Code: "0299", Description: "Neuquén / Río Negro / La Pampa" },
  "02983": { Code: "02983", Description: "Buenos Aires" },
  "02982": { Code: "02982", Description: "Buenos Aires" },
  "0298": { Code: "0298", Description: "Río Negro" },
  "02972": { Code: "02972", Description: "Neuquén" },
  "0297": { Code: "0297", Description: "Chubut / Santa Cruz" },
  "02966": { Code: "02966", Description: "Santa Cruz" },
  "02964": { Code: "02964", Description: "Tierra del Fuego" },
  "02963": { Code: "02963", Description: "Santa Cruz" },
  "02962": { Code: "02962", Description: "Santa Cruz" },
  "02954": { Code: "02954", Description: "La Pampa" },
  "02953": { Code: "02953", Description: "La Pampa" },
  "02952": { Code: "02952", Description: "La Pampa" },
  "02948": { Code: "02948", Description: "Neuquén" },
  "02946": { Code: "02946", Description: "Río Negro" },
  "02945": { Code: "02945", Description: "Chubut" },
  "02942": { Code: "02942", Description: "Neuquín / Río Negro" },
  "02940": { Code: "02940", Description: "Río Negro" },
  "0294": { Code: "0294", Description: "Río Negro / Neuquén / Chubut" },
  "02936": { Code: "02936", Description: "Buenos Aires" },
  "02935": { Code: "02935", Description: "Buenos Aires" },
  "02934": { Code: "02934", Description: "Río Negro" },
  "02933": { Code: "02933", Description: "Buenos Aires" },
  "02932": { Code: "02932", Description: "Buenos Aires" },
  "02931": { Code: "02931", Description: "Río Negro / La Pampa" },
  "02929": { Code: "02929", Description: "Buenos Aires" },
  "02928": { Code: "02928", Description: "Buenos Aires" },
  "02927": { Code: "02927", Description: "Buenos Aires" },
  "02926": { Code: "02926", Description: "Buenos Aires" },
  "02925": { Code: "02925", Description: "Buenos Aires / La Pampa" },
  "02924": { Code: "02924", Description: "Buenos Aires / La Pampa" },
  "02923": { Code: "02923", Description: "Buenos Aires" },
  "02922": { Code: "02922", Description: "Buenos Aires" },
  "02921": { Code: "02921", Description: "Buenos Aires" },
  "02920": { Code: "02920", Description: "Río Negro / Buenos Aires" },
  "0291": { Code: "0291", Description: "Buenos Aires" },
  "02903": { Code: "02903", Description: "Chubut" },
  "02902": { Code: "02902", Description: "Santa Cruz" },
  "02901": { Code: "02901", Description: "Tierra del Fuego" },
  "0280": { Code: "0280", Description: "Chubut" },
  "0266": { Code: "0266", Description: "San Luis" },
  "02658": { Code: "02658", Description: "San Luis" },
  "02657": { Code: "02657", Description: "San Luis" },
  "02656": { Code: "02656", Description: "San Luis" },
  "02655": { Code: "02655", Description: "San Luis" },
  "02652": { Code: "02652", Description: "San Luis" },
  "02651": { Code: "02651", Description: "San Luis" },
  "02648": { Code: "02648", Description: "San Juan" },
  "02647": { Code: "02647", Description: "San Juan" },
  "02646": { Code: "02646", Description: "San Juan" },
  "0264": { Code: "0264", Description: "San Juan" },
  "0263": { Code: "0263", Description: "Mendoza" },
  "02626": { Code: "02626", Description: "Mendoza" },
  "02625": { Code: "02625", Description: "Mendoza" },
  "02624": { Code: "02624", Description: "Mendoza" },
  "02622": { Code: "02622", Description: "Mendoza" },
  "0261": { Code: "0261", Description: "Mendoza" },
  "0260": { Code: "0260", Description: "Mendoza" },
  "0249": { Code: "0249", Description: "Buenos Aires" },
  "02478": { Code: "02478", Description: "Buenos Aires" },
  "02477": { Code: "02477", Description: "Buenos Aires / Santa Fe" },
  "02475": { Code: "02475", Description: "Buenos Aires" },
  "02474": { Code: "02474", Description: "Buenos Aires" },
  "02473": { Code: "02473", Description: "Buenos Aires / Santa Fe" },
  "02396": { Code: "02396", Description: "Buenos Aires" },
  "02395": { Code: "02395", Description: "Buenos Aires" },
  "02394": { Code: "02394", Description: "Buenos Aires" },
  "02393": { Code: "02393", Description: "Buenos Aires" },
  "02392": { Code: "02392", Description: "Buenos Aires" },
  "0237": { Code: "0237", Description: "Buenos Aires" },
  "0236": { Code: "0236", Description: "Buenos Aires" },
  "02358": { Code: "02358", Description: "Buenos Aires" },
  "02357": { Code: "02357", Description: "Buenos Aires" },
  "02356": { Code: "02356", Description: "Buenos Aires" },
  "02355": { Code: "02355", Description: "Buenos Aires" },
  "02354": { Code: "02354", Description: "Buenos Aires" },
  "02353": { Code: "02353", Description: "Buenos Aires / Santa Fe" },
  "02352": { Code: "02352", Description: "Buenos Aires" },
  "02346": { Code: "02346", Description: "Buenos Aires" },
  "02345": { Code: "02345", Description: "Buenos Aires" },
  "02344": { Code: "02344", Description: "Buenos Aires" },
  "02343": { Code: "02343", Description: "Buenos Aires" },
  "02342": { Code: "02342", Description: "Buenos Aires" },
  "02338": { Code: "02338", Description: "La Pampa" },
  "02337": { Code: "02337", Description: "Buenos Aires" },
  "02336": { Code: "02336", Description: "Córdoba" },
  "02335": { Code: "02335", Description: "La Pampa" },
  "02334": { Code: "02334", Description: "La Pampa" },
  "02333": { Code: "02333", Description: "La Pampa" },
  "02331": { Code: "02331", Description: "La Pampa" },
  "02326": { Code: "02326", Description: "Buenos Aires" },
  "02325": { Code: "02325", Description: "Buenos Aires" },
  "02324": { Code: "02324", Description: "Buenos Aires" },
  "02323": { Code: "02323", Description: "Buenos Aires" },
  "02320": { Code: "02320", Description: "Buenos Aires" },
  "02317": { Code: "02317", Description: "Buenos Aires" },
  "02316": { Code: "02316", Description: "Buenos Aires" },
  "02314": { Code: "02314", Description: "Buenos Aires" },
  "02302": { Code: "02302", Description: "La Pampa / Buenos Aires" },
  "0230": { Code: "0230", Description: "Buenos Aires" },
  "02297": { Code: "02297", Description: "Buenos Aires" },
  "02296": { Code: "02296", Description: "Buenos Aires" },
  "02292": { Code: "02292", Description: "Buenos Aires" },
  "02291": { Code: "02291", Description: "Buenos Aires" },
  "02286": { Code: "02286", Description: "Buenos Aires" },
  "02285": { Code: "02285", Description: "Buenos Aires" },
  "02284": { Code: "02284", Description: "Buenos Aires" },
  "02283": { Code: "02283", Description: "Buenos Aires" },
  "02281": { Code: "02281", Description: "Buenos Aires" },
  "02274": { Code: "02274", Description: "Buenos Aires" },
  "02273": { Code: "02273", Description: "Buenos Aires" },
  "02272": { Code: "02272", Description: "Buenos Aires" },
  "02271": { Code: "02271", Description: "Buenos Aires" },
  "02268": { Code: "02268", Description: "Buenos Aires" },
  "02267": { Code: "02267", Description: "Buenos Aires" },
  "02266": { Code: "02266", Description: "Buenos Aires" },
  "02265": { Code: "02265", Description: "Buenos Aires" },
  "02264": { Code: "02264", Description: "Buenos Aires" },
  "02262": { Code: "02262", Description: "Buenos Aires" },
  "02261": { Code: "02261", Description: "Buenos Aires" },
  "02257": { Code: "02257", Description: "Buenos Aires" },
  "02255": { Code: "02255", Description: "Buenos Aires" },
  "02254": { Code: "02254", Description: "Buenos Aires" },
  "02252": { Code: "02252", Description: "Buenos Aires" },
  "02246": { Code: "02246", Description: "Buenos Aires" },
  "02245": { Code: "02245", Description: "Buenos Aires" },
  "02244": { Code: "02244", Description: "Buenos Aires" },
  "02243": { Code: "02243", Description: "Buenos Aires" },
  "02242": { Code: "02242", Description: "Buenos Aires" },
  "02241": { Code: "02241", Description: "Buenos Aires" },
  "0223": { Code: "0223", Description: "Buenos Aires" },
  "02229": { Code: "02229", Description: "Buenos Aires" },
  "02227": { Code: "02227", Description: "Buenos Aires" },
  "02226": { Code: "02226", Description: "Buenos Aires" },
  "02225": { Code: "02225", Description: "Buenos Aires" },
  "02224": { Code: "02224", Description: "Buenos Aires" },
  "02223": { Code: "02223", Description: "Buenos Aires" },
  "02221": { Code: "02221", Description: "Buenos Aires" },
  "0221": { Code: "0221", Description: "Buenos Aires" },
  "02202": { Code: "02202", Description: "Buenos Aires" },
  "0220": { Code: "0220", Description: "Buenos Aires" },
  "011": { Code: "011", Description: "CABA" },
};
