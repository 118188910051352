import React from "react";
import pxToRem from "libs/pxToRem";
import dateToString from "libs/dateToString";
import splitCamelCase from "libs/splitCamelCase";
import TipoCreditoLabel from "components/business/tipoCreditoLabel/TipoCreditoLabel";
import LogoMG from "assets/logos/empresas/emp_mg.png";
import LogoMut from "assets/logos/empresas/emp_mut.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography/";
import EstadoSolicitudLabel from "components/business/estadoSolicitudLabel/EstadoSolicitudConsumoLabel";
import styled from "@material-ui/core/styles/styled";
import { SolicitudConsumoAnalisisResponse } from "api/types/originacion/services/analisisSolicitudTypes";
import { ETipoCredito } from "api/types/originacion/businessTypes";

const SolicitudHeaderConsumo: React.FC<{
  solicitud?: SolicitudConsumoAnalisisResponse;
  isLoading: boolean;
}> = ({ solicitud, isLoading = false }) => {
  if (isLoading || !solicitud) return <Loading color="secondary" />;

  let { titular } = solicitud;
  return (
    <Main>
      <TitleColumn>
        <Title>{`#${solicitud.id}`}</Title>
        <LabelContainer>
          {<TipoCreditoLabel tipoCredito={ETipoCredito.Consumo} />}
        </LabelContainer>
      </TitleColumn>
      <DataColumn>
        <DataHeader>Creada el</DataHeader>
        <Data>{dateToString(solicitud.fechaAlta)}</Data>
      </DataColumn>
      <DataColumn>
        <DataHeader>Titular</DataHeader>
        <Data>{`${titular.nombre} ${titular.apellido}`}</Data>
      </DataColumn>
      <DataColumn>
        <DataHeader>Punto de venta</DataHeader>
        <Data>{`${splitCamelCase(solicitud.canalNombre)}`}</Data>
      </DataColumn>

      <DataColumn>
        <DataHeader>Estado solicitud</DataHeader>
        <EstadoSolicitudContainer>
          <EstadoSolicitudLabel estadoSolicitud={solicitud.estado} />
        </EstadoSolicitudContainer>
      </DataColumn>
      <DataColumn>
        <LogoEmpresa
          empresaId={
            solicitud?.planComercial ? solicitud.planComercial.acreedor : 1
          }
        />
      </DataColumn>
    </Main>
  );
};

export default SolicitudHeaderConsumo;

const Loading = styled(CircularProgress)({
  alignSelf: "center",
});

const Main = styled("div")({
  display: "inline-flex",
  width: "100%",
  paddingLeft: pxToRem(19),
  paddingRight: pxToRem(20),
  flexWrap: "wrap",
  gap: pxToRem(25),
});
const TitleColumn = styled("div")({ display: "flex", flexDirection: "column" });
const DataColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  //maxWidth: pxToRem(165),
  paddingTop: pxToRem(10),
});
const Title = styled(Typography)({
  fontSize: pxToRem(22),
  fontWeight: "bold",
  lineHeight: 1.36,
  textAlign: "left",
  color: "#423d5c",
  wordWrap: "break-word",
  userSelect: "text",
  marginBottom: pxToRem(8),
});
const DataHeader = styled(Typography)({
  fontSize: pxToRem(12),
  marginBottom: pxToRem(12),
  fontWeight: 500,
  lineHeight: 1.25,
  textAlign: "left",
  color: "#9e9e9e",
  wordWrap: "break-word",
});
const Data = styled(Typography)({
  fontSize: pxToRem(14),
  fontWeight: "bold",
  lineHeight: 1.36,
  textAlign: "left",
  color: "#423d5c",
  wordWrap: "break-word",
  userSelect: "text",
});
const LabelContainer = styled("span")({
  display: "flex",
  alignItems: "center",
});
const EstadoSolicitudContainer = styled("div")({ maxWidth: 200 });

const LogoEmpresa: React.FC<{ empresaId?: number }> = ({ empresaId }) => {
  const EMPRESAS_LOGO = [
    { id: 1, src: LogoMG, height: 35, alt: "Logo-MG" },
    { id: 2, src: LogoMut, height: 45, alt: "Logo-Mutual" },
  ];
  const logo = EMPRESAS_LOGO.find((x) => x.id === empresaId);
  return (
    <>
      {logo && (
        <StyledImg
          alt={logo.alt}
          style={{
            height: logo.height,
          }}
          src={logo.src}
        />
      )}
    </>
  );
};

const StyledImg = styled("img")({
  marginTop: "auto",
  marginBottom: "auto",
});
