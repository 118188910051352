import React, { useState, ChangeEvent } from "react";
import Tabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import styled from "@material-ui/core/styles/styled";
import Ellipse from "components/styled/ellipse/Ellipse";

export interface ITabLabel {
  text: string;
  pendingActions?: number;
}

interface IProps {
  defaultValue?: number;
  disabled?: boolean;
  labels: ITabLabel[];
  onChange: (event: string) => void;
}

const DetailsTabs: React.FC<IProps> = ({
  defaultValue = 0,
  disabled,
  labels = [],
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (_: ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
      variant="fullWidth"
    >
      {labels.map((label, index) => (
        <Tab
          key={`detail-tab-${index}`}
          label={
            <TabLabel text={label.text} pendingActions={label.pendingActions} />
          }
          disabled={disabled}
        />
      ))}
    </Tabs>
  );
};

export default DetailsTabs;

const Tab = styled(MuiTab)({ fontWeight: 800 });

const TabLabel = ({ text, pendingActions = 0 }: ITabLabel) => {
  return (
    <TabContainer>
      {text}
      {pendingActions > 0 && (
        <Ellipse
          badgeContent={pendingActions.toString()}
          backgroundColor="#FAB673"
          color="#FFFFFF"
        />
      )}
    </TabContainer>
  );
};

const TabContainer = styled("span")({
  display: "flex",
  alignItems: "center",
});
