import getArea, { CODIGOS_DE_AREA_DE_ARGENTINA } from "./getArea";

function getPhoneFormat(numero: string): string {
  const codigoPais = numero.slice(0, 2);
  const resto = numero.slice(2);
  const codigoNueve = resto.slice(0, 1);
  let codigoArea = resto.slice(1, 4);

  if (CODIGOS_DE_AREA_DE_ARGENTINA.hasOwnProperty(codigoArea)) {
    codigoArea = codigoArea.replace(/^0+/, "");
  }

  const parte1 = resto.slice(4, 7);
  const parte2 = resto.slice(7);

  return `${codigoPais} ${codigoNueve} ${codigoArea} ${parte1} ${parte2}`;
}

interface AreaCode {
  Code: string;
  Name?: string;
}

interface PhoneInputFormat {
  area: AreaCode;
  number: string;
  isMobile: boolean;
}

export const getPhoneInputFormat = (tel: string): PhoneInputFormat => {
  const number = tel.replace("549", "0");
  const areaCode = getArea(number);

  if (!areaCode) {
    throw new Error("Invalid area code");
  }

  const numberWithoutAreaCode = number.slice(areaCode.Code.length);

  return {
    area: areaCode,
    number: `${areaCode.Code}15${numberWithoutAreaCode}`,
    isMobile: true,
  };
};

export default getPhoneFormat;
