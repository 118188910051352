import React from "react";
import {
  SolicitudConsumoAnalisisResponse,
  SolicitudAnalisisResponse,
} from "api/types/originacion/services/analisisSolicitudTypes";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { QueryFunction, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

import TableLoading from "components/styled/loadings/TableLoading";
import {
  CambioDeEstado,
  CambiosDeEstadoSolicitudRequest,
} from "api/types/originacion/services/CambioDeEstado";
import { solicitudesCambiosDeEstado } from "api/solicitudesCambiosDeEstado";
import { ETipoCredito } from "api/types/originacion/businessTypes";

const fetchCambiosDeEstado: QueryFunction<
  CambioDeEstado[],
  [string, CambiosDeEstadoSolicitudRequest]
> = async ({ queryKey, meta }) =>
  solicitudesCambiosDeEstado(meta?.token as string).getAll(queryKey[1]);

const SolicitudCambiosDeEstado: React.FC<ISolicitudCambiosDeEstado> = ({
  solicitud,
  isLoading,
}) => {
  const { isLoading: isLoadingCambios, data: cambiosDeEstado = [] } = useQuery(
    [
      "cambiosDeEstadoSolicitud",
      {
        solicitudId: solicitud?.id,
        tipoCredito: solicitud?.tipoCredito,
      } as CambiosDeEstadoSolicitudRequest,
    ],
    fetchCambiosDeEstado,
    { staleTime: 1000 * 60 * 1, enabled: !!solicitud }
  );

  return (
    <>
      {(isLoading || isLoadingCambios) && <TableLoading columns={4} rows={2} />}
      {!isLoading && !isLoadingCambios && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Comentario</TableCell>
                <TableCell>Usuario</TableCell>
              </TableRow>
            </TableHead>
            {(cambiosDeEstado?.length ?? 0) === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>Sin cambios</TableCell>
                </TableRow>
              </TableBody>
            )}
            {cambiosDeEstado &&
              cambiosDeEstado.map((cambio) => (
                <TableBody key={cambio.id}>
                  <TableRow hover style={{ cursor: "default" }}>
                    <TableCell>
                      {cambio.fecha &&
                        format(
                          Date.parse(cambio.fecha),
                          "dd 'de' MMMM 'de' yyyy 'a las' HH:mm",
                          {
                            locale: es,
                          }
                        )}
                    </TableCell>
                    <TableCell>{cambio.valor}</TableCell>
                    <TableCell>{cambio.comentario}</TableCell>
                    <TableCell>{cambio.usuarioDesc}</TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SolicitudCambiosDeEstado;

interface ISolicitudCambiosDeEstado {
  solicitud?: SolicitudConsumoAnalisisResponse | SolicitudAnalisisResponse;
  tipoCredito?: ETipoCredito;
  isLoading: boolean;
}
