import React from "react";
import {
  DataListItem,
  DataCheckbox,
  EditButton,
  DeleteButton,
  DataError,
} from "components/styled/dataCard/DataCard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import ContentLoader from "react-content-loader";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "@material-ui/core/styles/styled";

interface IDataCardRowProps {
  children?: React.ReactNode;
  isLoading: boolean;
  label?: any;
  value?: any;
  tooltip?: string;
  disableTypography?: boolean;
  checked?: boolean;
  deleted?: boolean;
  selected?: boolean;
  error?: string;
  onEdit?: () => void;
  onCheck?: () => void;
  onDelete?: () => void;
  onSelect?: () => void;
  selectable?: boolean;
}

const DataCardRow: React.FC<IDataCardRowProps> = ({
  isLoading,
  label,
  value,
  tooltip = "",
  children,
  disableTypography = false,
  checked = false,
  deleted = false,
  selected = false,
  error,
  onEdit,
  onCheck,
  onDelete,
  onSelect,
  selectable = false,
}) => {
  const showActions =
    onEdit || onCheck || onDelete || onSelect || deleted || checked || error;
  const isButton = onSelect ? true : false;

  const valueStyle: React.CSSProperties = selectable
    ? { userSelect: "text" }
    : {};

  return (
    <>
      <Divider variant="fullWidth" component="li" />
      <Tooltip title={tooltip} placement="right">
        <ListItem button={isButton as any} onClick={onSelect} disableGutters>
          <DataListItem primary={label} />
          <DataListItemBody>
            {isLoading && <LoadingItem />}
            <>
              {!isLoading && (
                <DataListItem
                  disableTypography={disableTypography}
                  secondary={
                    <span style={valueStyle}>{value ?? children}</span>
                  }
                />
              )}
              {showActions && !isLoading && (
                <ListItemIcon
                  style={{ minWidth: "auto", alignItems: "center" }}
                >
                  {error && <DataError message={error} />}
                  {onEdit && <EditButton onClick={onEdit} />}
                  {(onDelete || deleted) && (
                    <DeleteButton onClick={onDelete} value={deleted} />
                  )}
                  {onSelect && (
                    <Radio
                      onClick={onSelect}
                      checked={selected}
                      color="primary"
                    />
                  )}
                  {!error && onCheck && (
                    <DataCheckbox value={checked} onClick={onCheck} />
                  )}
                </ListItemIcon>
              )}
            </>
          </DataListItemBody>
        </ListItem>
      </Tooltip>
    </>
  );
};

const DataListItemBody = styled("div")({
  minWidth: "auto",
  display: "flex",
  alignItems: "center",
});
const LoadingItem: React.FC = () => {
  return (
    <StyledContentLoader
      height={20}
      width={180}
      speed={1}
      backgroundColor="#f7f8ff"
      foregroundColor="#9e9e9e"
    >
      <rect x="10" y="4" rx="4" ry="4" width="160" height="12" />
    </StyledContentLoader>
  );
};
const StyledContentLoader = styled(ContentLoader)({
  width: 180,
  height: "80%",
});

export default DataCardRow;
