import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import pxToRem from "libs/pxToRem";
import Modal from "components/styled/modal/Modal";

const DesistirSolicitud: React.FC<{
  handleDesistir: (motivo: string) => void;
  isLoading: boolean;
}> = ({ handleDesistir, isLoading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [motivo, setMotivo] = useState("");
  const canSubmit = motivo !== "" && !isLoading;
  const handleClose = () => {
    setModalIsOpen(false);
    setMotivo("");
  };
  const handleSubmit = () => {
    handleDesistir(motivo);
  };
  return (
    <>
      <Button
        disabled={true}
        variant="contained"
        color="secondary"
        size="small"
        style={{ marginLeft: pxToRem(8), height: pxToRem(28) }}
        onClick={(e) => setModalIsOpen(!modalIsOpen)}
      >
        Desistir
      </Button>
      <Modal
        maxWidth="sm"
        opened={modalIsOpen}
        onClose={handleClose}
        title="El cliente desiste la solicitud de crédito"
        dialogActions={
          <Button
            disabled={!canSubmit}
            onClick={handleSubmit}
            variant="contained"
            size="small"
            style={{
              minWidth: pxToRem(140),
            }}
            color="secondary"
            fullWidth={false}
          >
            {isLoading && "Enviando"}
            {!isLoading && "Enviar"}
          </Button>
        }
      >
        <TextField
          onChange={(e) => {
            setMotivo(e.target.value);
          }}
          value={motivo}
          placeholder="Motivo"
          style={{ width: pxToRem(420) }}
        />
      </Modal>
    </>
  );
};
export default DesistirSolicitud;
