import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { ETipoCredito } from "api/types/originacion/businessTypes";
import pxToRem from "libs/pxToRem";

const RedLabel = withStyles((theme) => ({
  badge: {
    backgroundColor: "#dd4e11",
    color: "white",
    transform: "scale(1)",
    position: "relative",
  },
  root: {
    display: "inline-block",
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8),
  },
}))(Badge);

const GreenLabel = withStyles((theme) => ({
  badge: {
    backgroundColor: "#4ca793",
    color: "white",
    transform: "scale(1)",
    position: "relative",
  },
  root: {
    display: "inline-block",
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8),
  },
}))(Badge);

const BlueLabel = withStyles((theme) => ({
  badge: {
    backgroundColor: "#c7f2fa",
    color: "#009bba",
    transform: "scale(1)",
    position: "relative",
  },
  root: {
    display: "inline-block",
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8),
  },
}))(Badge);

const TipoCreditoLabel: React.FC<{
  tipoCredito: ETipoCredito;
}> = ({ tipoCredito }) => {
  switch (tipoCredito) {
    case ETipoCredito.Prendario:
      return <GreenLabel badgeContent="Prendario" overlap="rectangular" />;
    case ETipoCredito.Autoprenda:
      return <RedLabel badgeContent="Autoprenda" overlap="rectangular" />;
    case ETipoCredito.Consumo:
      return <BlueLabel badgeContent="Consumo" overlap="rectangular" />;
    default:
      return <BlueLabel badgeContent="Error" overlap="rectangular" />;
  }
};

export default TipoCreditoLabel;
