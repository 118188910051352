import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DetailsLayout from "components/styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs, {
  ITabLabel,
} from "components/styled/layouts/detailsLayout/DetailsTabs";
import useSolicitudIdParam from "components/hooks/useSolicitudIdParam";
import { useSolicitudConsumo } from "components/hooks/useGetSolicitudConsumo";
import SolicitudHeaderConsumo from "components/business/solicitudHeaderConsumo/SolicitudHeaderConsumo";
import SolicitudDetalleConsumo from "components/business/solicitudDetalleConsumo/SolicitudDetalleConsumo";
import SolicitudRevisionActions from "./SolicitudRevisionActions";
import { Typography } from "@material-ui/core";
import SolicitudCambiosDeEstado from "components/app/analisis/solicitudAnalisis/SolicitudCambiosDeEstado";

const SolicitudRevision = () => {
  const solicitudId = useSolicitudIdParam();
  const history = useHistory();

  const { data: solicitud, isLoading } = useSolicitudConsumo(solicitudId, true);

  const [currentTab, setCurrentTab] = useState(0);
  const tabs: ITabLabel[] = [
    { text: "DETALLE" },
    { text: "HISTORIA" },
    { text: "CALLBACK" },
    { text: "TAREAS", pendingActions: solicitud?.tareasPendientes },
  ];

  return (
    <DetailsLayout
      actionButtonsComponent={
        solicitud && <SolicitudRevisionActions solicitud={solicitud} />
      }
      tabsComponent={
        <DetailsTabs
          labels={tabs}
          onChange={(value) => {
            setCurrentTab(parseInt(value));
          }}
        />
      }
      toolbar={
        <SolicitudHeaderConsumo isLoading={isLoading} solicitud={solicitud} />
      }
      onBackClick={() => {
        history.push("/revision");
      }}
      addPadding
    >
      {currentTab === 0 && (
        <SolicitudDetalleConsumo solicitud={solicitud} isLoading={isLoading} />
      )}
      {currentTab === 1 && (
        <SolicitudCambiosDeEstado solicitud={solicitud} isLoading={isLoading} />
      )}

      {currentTab === 2 && <Typography>CALLBACK</Typography>}
      {currentTab === 3 && <Typography>TAREAS</Typography>}
    </DetailsLayout>
  );
};

export default SolicitudRevision;
