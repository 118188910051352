import jsonFetch from "./jsonFetch";
import environment from "environment";
import {
  CambiosDeEstadoSolicitudRequest,
  CambioDeEstado,
} from "./types/originacion/services/CambioDeEstado";

const solicitudesCambiosDeEstado = (token: string) => {
  return {
    getAll: (
      request: CambiosDeEstadoSolicitudRequest
    ): Promise<CambioDeEstado[]> => {
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.tipoCredito}/${request.solicitudId}/cambiosdeestado`,
      });
    },
  };
};

export { solicitudesCambiosDeEstado };
