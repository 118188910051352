import React from "react";
import NumberFormat from "react-number-format";

interface IProps {
  value?: number;
  [key: string]: any;
}

const CurrencyText: React.FC<IProps> = ({ value = 0, ...otherProps }) => {
  return (
    <NumberFormat
      displayType={"text"}
      isNumericString
      prefix="$"
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={0}
      value={value}
      {...otherProps}
    />
  );
};

export default CurrencyText;
