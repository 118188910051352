import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { EEstadoSolicitudConsumo } from "api/types/originacion/businessTypes";

const YellowLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff7cc",
    color: "#ee9d00",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const RedLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffe0e0",
    color: "#dd1111",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const BlueLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#c7f2fa",
    color: "#009bba",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const GreenLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#d2f6e2",
    color: "#069661",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const OrangeLabel = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffeece",
    color: "#f17f00",
    borderRadius: 4,
    padding: 4,
    height: "auto",
  },
  label: { fontWeight: "bold" },
}))(Chip);

const EstadoSolicitudConsumoLabel: React.FC<{
  estadoSolicitud: EEstadoSolicitudConsumo;
}> = ({ estadoSolicitud }) => {
  switch (estadoSolicitud) {
    case EEstadoSolicitudConsumo.Rechazada:
      return <RedLabel label="Rechazada" />;
    case EEstadoSolicitudConsumo.En_Analisis:
      return <YellowLabel label="En Análisis" />;
    case EEstadoSolicitudConsumo.Desistida:
      return <RedLabel label="Desistida" />;
    case EEstadoSolicitudConsumo.Aprobada:
      return <GreenLabel label="Aprobada" />;

    case EEstadoSolicitudConsumo.Incompleta:
      return <BlueLabel label="Incompleta" />;
    case EEstadoSolicitudConsumo.Liquidada:
      return <OrangeLabel label="Liquidada" />;
    default:
      return <YellowLabel label="En Análisis" />;
  }
};

export default EstadoSolicitudConsumoLabel;
