import React from "react";
import NumberFormat from "react-number-format";

interface IProps {
  value?: number | string;
  [key: string]: any;
}

const DniText: React.FC<IProps> = ({ value = "", ...otherProps }) => {
  return (
    <NumberFormat
      id="dni"
      displayType={"text"}
      value={value}
      isNumericString
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={0}
      {...otherProps}
    />
  );
};

export default DniText;
